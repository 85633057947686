const routes = [
  {
    path: "/",
    name: "index",
    component: () => import(/* webpackChunkName: "home" */ "../pages/Index"),
    meta: {
      title: "صفحه اصلی",
    },
    children: [
      {
        path: "/",

        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../pages/Home"),
        meta: {
          title: "صفحه اصلی",
        },
      },
      {
        path: "/product/:name",

        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductDetails"),
        meta: {
          title: "محصول",
        },
        name: "product.detail",
      },
      {
        path: "/products",

        name: "products",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductList"),
        meta: {
          title: "لیست محصولات",
        },
      },
      {
        path: "/category/*",

        name: "products.category",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/ProductList"),
        meta: {
          title: "لیست محصولات",
        },
      },
      {
        path: "/cart",

        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/Cart"),
        meta: {
          title: "سبد خرید",
        },
        name: "cart",
      },
      {
        path: "/user-panel",

        name: "panel",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/UserPanel"),
        meta: {
          title: "پنل کاربری",
          auth: true,
        },
      },

      {
        path: "/order/:id",

        name: "order",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/order"),
        meta: {
          title: "سفارشات",
          auth: true,
        },
      },
      {
        path: "/callback",

        name: "callback",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/callback"),
        meta: {
          title: "در حال هدایت",
        },
      },
      ,
      {
        path: "/about-us",

        name: "about-us",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/AboutUs"),
        meta: {
          title: "درباره ما",
        },
      },
      {
        path: "/contact",

        name: "contact",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/Contact"),
        meta: {
          title: "ارتباط با ما",
        },
      },
      {
        path: "/common-questions",

        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/CommonQuestions"),
        meta: {
          title: "پرسش های متداول",
        },
      },

      {
        name: "page",
        path: "/pages/:page/:slug?",

        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/pages.vue"),
        meta: {
          title: "صفحات ",
        },
      },
      {
        path: "/payment-method",
        name: "payment-method",

        component: () =>
          import(
            /* webpackChunkName: "front" */ "../components/Static/PaymentMethod.vue"
          ),
        meta: {
          title: "شیوه پرداخت ",
        },
      },
      {
        path: "/rules",
        name: "rules",

        component: () =>
          import(
            /* webpackChunkName: "front" */ "../components/Static/Rules.vue"
          ),
        meta: {
          title: "قوانین و مقررات",
        },
      },
      {
        path: "/sending-product",
        name: "sending-product",

        component: () =>
          import(
            /* webpackChunkName: "front" */ "../components/Static/SendingProduct.vue"
          ),
        meta: {
          title: "رویه ارسال کالا",
        },
      },
      {
        name: "blog.index",
        path: "/mag",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogList.vue"),
        meta: {
          title: "مطالب",
        },
      },
      {
        name: "weblogList",
        path: "/mag/*",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogList.vue"),
        meta: {
          title: "مطالب",
        },
      },
      {
        name: "blogDetail",
        path: "weblog/:name",
        component: () =>
          import(/* webpackChunkName: "front" */ "../pages/WeblogDetails.vue"),
        meta: {
          title: "مطلب",
        },
      },
      {
        path: "/branches",
        name: "branches",

        component: () =>
          import(
            /* webpackChunkName: "front" */ "../components/Static/Branches.vue"
          ),
        meta: {
          title: "جذب نمایندگی",
        },
      },
      {
        path: "/compare",

        name: "compare",
        component: () => import("@/parts/Front/pages/Compare"),
      },
      {
        path: "/404",

        name: "404",
        component: () => import("@/parts/Front/pages/404"),
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "front" */ "../pages/LoginAndRegister"),
    meta: {
      title: "عضویت",
    },
  },
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next("/404");
    },
  },
];
// 

export default routes;
