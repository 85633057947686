import axios from 'axios'
import router from '../../../../main'
const state = {
    blogDetail: null,
    blogDetailStatus: false,
    postList: null,
    statusAllBlog: false,
};
const getters = {
    getStatusAllBlog(state) {
        return state.statusAllBlog;
    },
    getAllBlog(state) {
        return state.postList;
    },
    getBlogDetail(state) { //دریافت جزئیات پست
        return state.blogDetail;
    },
    getBlogDetailStatus(state) { //دریافت استاستوس چزئیات پست برای نمایش لودینگ
        return state.blogDetailStatus;
    }
};
const mutations = {
    setStatusAllBlog(state, status) {
        state.statusAllBlog = status;
    },
    setPostList(state, list) {
        state.postList = list;
    }, //ست کردن لیست پست ها
    setBlogDetail(state, data) { //ست کردن جزئیات پست
        state.blogDetail = data;
    },
    setBlogDetailStatus(state, status) { //ست کردن استاتوس جزئیات پست
        state.blogDetailStatus = status;
    }
};
const actions = {
    async getBlogDetailFromServer({ commit }, id) {
        //دریافت جزئیات پست از سرور ابتدا لودینگ تروو میشود و بعد از دریافت اطلاعات فالس میشود
        commit("setBlogDetailStatus", true);
        await axios.get(`/front/posts/${id}?withEnTitle=1`).then(response => {
            commit("setBlogDetail", response.data.data.post);
            commit("setBlogDetailStatus", false);
        }).catch(error => {
            if (error.status === 404) {
                router.push("/")
            }
        });
    },
    async sendBlogComment({ commit }, pack) {
        //ارسال کامنت های هر پست به سمت  سرور
        //جواب های هر کامنت نیز از این فانکشن ارسال میشود
        await axios.post("/front/posts/" + pack.id + "/comments", pack.comment).then(res => {
            window.swal({
                title: "دیدگاه شما ارسال شد",
                text: "با تایید ما دیدگاه شما بزودی نمایش داده خواهد شد",
                icon: "success",
            })
        }).catch(error => {
            if (error.status == 401) {
                router.push("/login")
            }
        });
    },
    async getAllBlogFromServer({ commit }, data) {
        //در ابتدا استانوس ترو میشود
        commit("setStatusAllBlog", true)
            //بعد از دریافت اطلاعات استاتوس فالس میشود تا نمایش لودر پایان یابد
        if (data.page != undefined) {
            // در صورت صفحه بندی
            if (data.category != undefined) {
                //در صورت فیلترشدن
                await axios.get("/front/posts/category/" + data.category, {
                    params: {
                        page: data.page
                    }
                }).then(res => {
                    commit("setStatusAllBlog", false)
                    commit("setPostList", res.data.data)
                }).catch(error => { if (error.status === 404) { router.push("/weblog-list") } })
            } else { //دسته بندی ندارد اما صفحه بندی دارد
                await axios.get("/front/posts", {
                    params: {
                        page: data.page
                    }
                }).then(res => {
                    commit("setStatusAllBlog", false)
                    commit("setPostList", res.data.data)
                }).catch(error => { if (error.status === 404) { router.push("/weblog-list") } })
            }
        } else {
            // صفحه اول با فیلتر
            if (data.category != undefined) {
                await axios.get("/front/posts/category/" + data.category).then(res => {
                    commit("setStatusAllBlog", false)
                    commit("setPostList", res.data.data)
                }).catch(error => { if (error.status === 404) { router.push("/weblog-list") } })
            } else {
                // صفحه اول
                await axios.get("/front/posts").then(res => {
                    commit("setStatusAllBlog", false)
                    commit("setPostList", res.data.data)
                }).catch(error => { if (error.status === 404) { router.push("/weblog-list") } })

            }
        }

    },
    async searchInBlog({ commit }, search) {
        await axios.get("/front/posts", {
            params: {
                title: search
            }
        }).then(res => {
            commit("setPostList", res.data.data)

        }).catch(error => {
            if (error.status === 404) {
                commit("setPostList", []);

            }
        });
    }

};
export default {
    state,
    getters,
    mutations,
    actions
}
