import axios from "axios";
import router from "../../../../main";
const state = {
  list: null,
  postCategories:null
};
const getters = {
  AllPostCategories(state){
    return state.postCategories
  },
  allCategories(state) {
    return state.list;
  },
};
const mutations = {
  setCategories(state, list) {
    state.list = list;
  },
  setPostCategories(state,list){
    state.postCategories=list;
  }
};
const actions = {
  async getAllPostCategoriesFromDB({ commit }) {
    await axios.get("/front/post-categories").then((response) => {
      commit("setPostCategories", response.data.data.postCategories);
    });
  },
  async getAllCategoriesFromDB({ commit, dispatch }) {
    let result = null;
    await axios.get("/front/categories?all=1").then((response) => {
      dispatch("returnChild", response.data.data.categories).then((res) => {
        commit("setCategories", res);
        result = res;
      });
    });
    return result;
  },
  returnChild({ dispatch }, mainList) {
    let list = [];
    mainList.forEach((item) => {
      if (item.children && item.children.length) {
        list.push(item);
        dispatch("returnChild", item.children).then((res) => {
          list.push(...res);
        });
      } else {
        list.push(item);
      }
    });
    return list;
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
